import React from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';

import Layout from '../Layout';
import useStoryblok from '../../../../lib/storyblok';
import Notification from '../../../v1/molecules/notification';
import { Section } from '../Section';
import Bloks from '../../../atoms/Bloks';
import LandingPageHeader from '../../molecules/LandingPageHeader';
import {
  FiftyFiftySection,
  FiftySectionLeft,
  FiftySectionRight,
} from '../../../v1/templates/fiftyfiftySection';
import LandingForm from '../../../v1/Form/landingForm';
import Grid from '../../atoms/Grid/Grid';
import FiftyFiftyFeature from '../../molecules/FiftyFiftyFeature';
import { Location } from '../../../../types';

type Props = {
  location: Location;
  pageContext: any;
  data: any;
};

const LandingPageTemplate: React.FC<Props> = ({ location, pageContext, data }) => {
  let story = pageContext.story;
  story = useStoryblok(story, location);

  let HeaderAndFooterData = useStoryblok(data.headerAndFooter, location);
  const { content } = HeaderAndFooterData;

  const isRichText = story.content.sectionOne[0].component === 'rich-text';
  const hideForm = story.content.hide_form;
  const formOnRight = story.content.form_right;
  const downloadUrl = story.content.FormDownloadUrl?.url;

  const isFiftyFifty = (section: any) => section?.component === 'fifty-fifty-feature';

  const formName = `${story.name.trim().split(' ').join('-').toLowerCase()}-form`;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={story.content.SEO} />
      <Section curved hero curveColor="tertiary">
        <h2 className="col-span-4 sm:col-start-2 sm:col-end-8 lg:col-start-2 lg:col-end-12 text-center text-5xl font-serif font-bold py-5 mb-10 sm:mb-15 lg:mb-0 z-10">
          {story.content.header[0].header}
        </h2>
      </Section>
      <LandingPageHeader content={story.content.header[0]} hideText />
      <Grid>
        <Section
          className={`col-span-4 lg:col-span-12 pt-16 ${
            isRichText
              ? `sm:col-span-8 lg:col-start-3 lg:col-end-11`
              : `lg:col-start-2 lg:col-end-12`
          }`}
          noGrid
        >
          {isFiftyFifty(story.content.sectionOne[0]) ? (
            <FiftyFiftyFeature {...story.content.sectionOne[0]} />
          ) : (
            <Bloks bloks={story.content.sectionOne} />
          )}
        </Section>
        {story.content.sectionTwo[0] && (
          <Section className="col-span-4 lg:col-start-2 lg:col-end-12 pt-16" noGrid>
            {isFiftyFifty(story.content.sectionTwo[0]) ? (
              <FiftyFiftyFeature {...story.content.sectionTwo[0]} />
            ) : (
              <Bloks bloks={story.content.sectionTwo} />
            )}
          </Section>
        )}
      </Grid>
      <Grid>
        <Section className="py-16 sm-py-0 col-span-4 lg:col-span-12 sm:col-span-8 px-0 lg:px-0">
          {!hideForm ? (
            <FiftyFiftySection className="lg:col-start-2 flex justify-center" noMarginBottom>
              <FiftySectionLeft className={`mb-6 ${formOnRight ? `lg:col-end-5` : `lg:col-end-6`}`}>
                {formOnRight ? (
                  <Bloks bloks={story.content.sectionThree} />
                ) : (
                  <LandingForm
                    formName={formName}
                    className="sm:max-w-xl sm:mb-6"
                    pathname={`/${story.full_slug}`}
                    downloadUrl={downloadUrl}
                  />
                )}
              </FiftySectionLeft>
              <FiftySectionRight className={`${formOnRight ? `xl:col-start-6` : `xl:col-start-7`}`}>
                {formOnRight ? (
                  <LandingForm
                    formName={formName}
                    className="sm:max-w-xl"
                    pathname={`/${story.full_slug}`}
                    downloadUrl={downloadUrl}
                  />
                ) : (
                  <Bloks bloks={story.content.sectionThree} />
                )}
              </FiftySectionRight>
            </FiftyFiftySection>
          ) : (
            <div className="col-span-4 sm:col-span-6 sm:col-start-2 lg:col-start-2 lg:col-end-12">
              <Bloks bloks={story.content.sectionThree} />
            </div>
          )}
        </Section>
      </Grid>
      <Bloks bloks={story.content.sectionFour} />
      <Bloks bloks={story.content.footerCTA} />

      <Notification isVisible={queryString.parse(location.search).formSubmit === 'success'} />
    </Layout>
  );
};

export default LandingPageTemplate;

export const query = graphql`
  query landingPagesVersionTwo {
    headerAndFooter: storyblokEntry(full_slug: { eq: "blog/" }) {
      content
    }
  }
`;

import React from 'react';
import cn from 'classnames';

import storyblokImageResizeUrl from '../../../../lib/storyblok-image-resize';
import { Section } from '../../templates/Section';
import Bloks from '../../../atoms/Bloks';

type Props = {
  content: any;
  hideText: boolean;
};

const LandingPageHeader: React.FC<Props> = ({ content, hideText }) => {
  return (
    <div className="mt-40">
      {content.fullpage_width_image ? (
        <>
          {!hideText && (
            <h1 className="max-w-7xl mx-auto text-center my-6 font-serifBold text-2rem lg:text-5xl">
              {content.header}
            </h1>
          )}
          <img
            className="mx-auto"
            src={storyblokImageResizeUrl(content.image.filename, 0, 0, true)}
          />
        </>
      ) : (
        <Section>
          <div className="max-w-7xl mx-auto mb-6 font-serifBold text-2rem lg:text-5xl col-span-4 md:col-span-4  lg:col-start-2 xl:col-end-6  text-left">
            {!hideText && content.header}
            {!hideText && content.CTA.length > 0 && (
              <div className="font-sans mt-6">
                <Bloks bloks={content.CTA} />
              </div>
            )}
          </div>
          <img
            className={cn('col-span-4 md:col-span-4 lg:col-start-7 lg:col-end-12', {
              'lg:col-start-5 lg:col-end-9': hideText,
            })}
            src={storyblokImageResizeUrl(content.image.filename, 0, 0, true)}
          />
          {hideText && content.CTA.length > 0 && (
            <div className="font-sans mt-6 col-span-4 md:col-span-8 lg:col-start-6 lg:col-end-8 text-center">
              <Bloks bloks={content.CTA} />
            </div>
          )}
        </Section>
      )}
    </div>
  );
};

export default LandingPageHeader;

import React from 'react';
import RichText from '../../../atoms/RichText';
import StoryblokImageResizeUrl from '../../../../lib/storyblok-image-resize';
import cn from 'classnames';

const FiftySection = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={`mt-10 xl:mt-0 w-full col-span-6 lg:col-span-5 ${className}`}>{children}</div>
);

type Props = {
  header: string;
  description: string;
  image: {
    filename: string;
    alt: string;
  };
  reverse: string;
  text: string;
  noMarginBottom: boolean;
  fullWidthImage: string;
  columns: any;
};

const FiftyFiftySection: React.FC<Props> = ({
  header,
  description,
  image,
  reverse,
  text,
  noMarginBottom,
  fullWidthImage,
  columns,
}) => {
  const imageWidth = fullWidthImage ? 0 : 211;

  return (
    <div
      className={`col-span-6 ${
        columns ? `` : `flex flex-col lg:gap-x-4`
      } lg:col-start-2 lg:grid lg:grid-cols-10 gap-x-5 lg:items-center ${
        noMarginBottom ? 'mb-0' : 'mb-12'
      }`}
    >
      <FiftySection className={cn({ 'sm:row-start-0 sm:row-end-1 lg:row-start-1 ': columns })}>
        {image && (
          <img
            className="w-auto m-auto"
            src={StoryblokImageResizeUrl(image.filename, imageWidth)}
            alt={image.alt}
            loading="lazy"
          />
        )}
      </FiftySection>
      <FiftySection
        className={cn({
          'lg:order-first': !reverse,
          'sm:row-start-0 sm:row-end-1 lg:row-start-1': columns,
        })}
      >
        <h3 className="font-serif text-bold text-2xl">{header}</h3>
        <p>{description}</p>
        {text && <RichText document={text} />}
      </FiftySection>
    </div>
  );
};

export default FiftyFiftySection;

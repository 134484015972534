import React from 'react';
import PropTypes from 'prop-types';

const FiftyFiftySection = ({ children, className, noMarginBottom }) => {
  return (
    <div
      className={`col-span-10 flex flex-col xl:grid grid-cols-10 gap-x-4 xl:items-center ${className} ${
        noMarginBottom ? `mb-0` : `mb-12 xl:mb-36`
      }`}
    >
      {children}
    </div>
  );
};

FiftyFiftySection.propTypes = {
  children: PropTypes.node,
};

const FiftySectionLeft = ({ children, className = '' }) => {
  return (
    <div className={`col-span-6 xl:col-start-1 xl:col-end-6 mt-10 xl:mt-0 w-full ${className}`}>
      {children}
    </div>
  );
};

FiftySectionLeft.propTypes = {
  children: PropTypes.node,
};

const FiftySectionRight = ({ children, className = '' }) => {
  return (
    <div className={`col-span-6 xl:col-start-6 xl:col-end-11 w-full ${className}`}>{children}</div>
  );
};

FiftySectionRight.propTypes = {
  children: PropTypes.node,
};

export { FiftyFiftySection, FiftySectionLeft, FiftySectionRight };
